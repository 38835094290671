import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/node_modules/antd/es/typography/Paragraph.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/node_modules/antd/es/typography/Text.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/app/(main)/skeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/components/Flex/Flex.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/components/Typography/Text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/components/Typography/Title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductProvider"] */ "/vercel/path0/storefront/src/lib/providers/product/product-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VariantImagesProvider"] */ "/vercel/path0/storefront/src/lib/providers/product/variant-images-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/modules/common/components/localized-client-link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/modules/products/components/product-list/pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/modules/products/components/product-preview/price.tsx");
